import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from 'FRONTEND/images/file-type-icon-csv.png'
import _imports_1 from 'FRONTEND/images/file-type-icon-docx.png'
import _imports_2 from 'FRONTEND/images/file-type-icon-image.png'
import _imports_3 from 'FRONTEND/images/file-type-icon-pdf.png'
import _imports_4 from 'FRONTEND/images/file-type-icon-ppt.png'
import _imports_5 from 'FRONTEND/images/file-type-icon-xls.png'
import _imports_6 from 'FRONTEND/images/file-type-icon-zip.png'
import _imports_7 from 'FRONTEND/images/file-type-icon-generic.png'
import _imports_8 from 'FRONTEND/images/linkedin-icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "is-flex is-justify-content-space-between"
}
const _hoisted_2 = {
  class: "rtitle sm",
  style: {"margin-bottom":"24px"}
}
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "lead-details-columns" }
const _hoisted_5 = { class: "lead-details-grid" }
const _hoisted_6 = {
  class: "lead-name",
  style: {"margin-bottom":"0.286rem"}
}
const _hoisted_7 = { class: "main-details-column bold" }
const _hoisted_8 = { class: "additional-details-column bold" }
const _hoisted_9 = { class: "leadfield" }
const _hoisted_10 = { class: "leadvalue" }
const _hoisted_11 = { class: "leadfield" }
const _hoisted_12 = { class: "leadvalue" }
const _hoisted_13 = { class: "leadfield" }
const _hoisted_14 = { class: "leadvalue" }
const _hoisted_15 = { class: "leadfield" }
const _hoisted_16 = { class: "leadvalue" }
const _hoisted_17 = { class: "leadfield" }
const _hoisted_18 = { class: "leadvalue" }
const _hoisted_19 = {
  key: 0,
  class: "bold"
}
const _hoisted_20 = { class: "leadfield" }
const _hoisted_21 = { class: "leadvalue" }
const _hoisted_22 = { class: "leadfield" }
const _hoisted_23 = { class: "leadvalue" }
const _hoisted_24 = { class: "leadfield" }
const _hoisted_25 = { class: "leadvalue" }
const _hoisted_26 = { class: "leadfield" }
const _hoisted_27 = { class: "leadvalue" }
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "leadfield" }
const _hoisted_30 = { class: "leadvalue" }
const _hoisted_31 = { class: "leadfield" }
const _hoisted_32 = { class: "leadvalue" }
const _hoisted_33 = ["href"]
const _hoisted_34 = { class: "leadfield" }
const _hoisted_35 = { class: "leadvalue" }
const _hoisted_36 = { class: "leadfield linkedin" }
const _hoisted_37 = { class: "leadvalue" }
const _hoisted_38 = ["href"]
const _hoisted_39 = { class: "leadfield" }
const _hoisted_40 = { class: "leadvalue" }
const _hoisted_41 = { class: "leadfield" }
const _hoisted_42 = { class: "leadvalue" }
const _hoisted_43 = { class: "lead-freetext-comment mt-3" }
const _hoisted_44 = { class: "smalltext semibold mb-1" }
const _hoisted_45 = {
  key: 0,
  class: "lead-freetext-comment-area"
}
const _hoisted_46 = {
  key: 0,
  class: "lead-tags mt-3"
}
const _hoisted_47 = { class: "lead-location-preview" }
const _hoisted_48 = { class: "lead-map-address-container" }
const _hoisted_49 = { class: "lead-map-address" }
const _hoisted_50 = { class: "mb-5 is-relative" }
const _hoisted_51 = { class: "lead-details-columns" }
const _hoisted_52 = { class: "lead-details-grid edit" }
const _hoisted_53 = { class: "bold" }
const _hoisted_54 = { class: "bold" }
const _hoisted_55 = { class: "editfield" }
const _hoisted_56 = { class: "editfield" }
const _hoisted_57 = { class: "editfield" }
const _hoisted_58 = { class: "editfield" }
const _hoisted_59 = { class: "editfield" }
const _hoisted_60 = { class: "editfield" }
const _hoisted_61 = { class: "editfield" }
const _hoisted_62 = { class: "editfield hq-checkbox" }
const _hoisted_63 = { class: "editfield" }
const _hoisted_64 = { class: "editfield" }
const _hoisted_65 = { class: "editfield" }
const _hoisted_66 = { class: "editfield" }
const _hoisted_67 = { class: "editfield" }
const _hoisted_68 = { class: "editfield" }
const _hoisted_69 = { class: "editfield" }
const _hoisted_70 = { class: "editfield" }
const _hoisted_71 = { class: "editfield comments" }
const _hoisted_72 = { class: "editfield tags" }
const _hoisted_73 = { class: "lead-location-wrapper" }
const _hoisted_74 = { class: "lead-map-address-container" }
const _hoisted_75 = {
  key: 0,
  class: "lead-map-address"
}
const _hoisted_76 = {
  key: 1,
  class: "lead-map-address"
}
const _hoisted_77 = {
  key: 0,
  class: "buttons is-justify-content-flex-end mr-4"
}
const _hoisted_78 = { class: "rtitle normalcase semibold mb-3" }
const _hoisted_79 = { class: "document-link-cards is-relative mb-6" }
const _hoisted_80 = { class: "document-link-card-icon" }
const _hoisted_81 = {
  key: 0,
  src: _imports_0,
  style: {"height":"40px"}
}
const _hoisted_82 = {
  key: 1,
  src: _imports_1,
  style: {"height":"40px"}
}
const _hoisted_83 = {
  key: 2,
  src: _imports_2,
  style: {"height":"40px"}
}
const _hoisted_84 = {
  key: 3,
  src: _imports_3,
  style: {"height":"40px"}
}
const _hoisted_85 = {
  key: 4,
  src: _imports_4,
  style: {"height":"40px"}
}
const _hoisted_86 = {
  key: 5,
  src: _imports_5,
  style: {"height":"40px"}
}
const _hoisted_87 = {
  key: 6,
  src: _imports_6,
  style: {"height":"40px"}
}
const _hoisted_88 = {
  key: 7,
  src: _imports_7,
  style: {"height":"40px"}
}
const _hoisted_89 = ["onClick"]
const _hoisted_90 = { class: "document-link-card-text" }
const _hoisted_91 = { class: "document-link-card-url" }
const _hoisted_92 = {
  key: 1,
  class: "document-link-card-buttons"
}
const _hoisted_93 = ["onClick"]
const _hoisted_94 = ["onClick"]
const _hoisted_95 = { class: "document-link-card-content edit" }
const _hoisted_96 = {
  class: "edit-document-link-inputs",
  style: {"margin-bottom":"12px"}
}
const _hoisted_97 = { class: "buttons is-justify-content-flex-end" }
const _hoisted_98 = {
  key: 1,
  class: "document-link-card is-relative"
}
const _hoisted_99 = { class: "document-link-card-content new" }
const _hoisted_100 = {
  key: 0,
  class: "has-text-danger smalltext uppercase"
}
const _hoisted_101 = {
  key: 1,
  class: "has-text-danger smalltext",
  style: {"margin-bottom":"1.2rem"}
}
const _hoisted_102 = {
  class: "edit-document-link-inputs",
  style: {"margin-bottom":"12px"}
}
const _hoisted_103 = {
  class: "edit-document-link-inputs",
  style: {"margin-bottom":"12px"}
}
const _hoisted_104 = { class: "buttons is-justify-content-flex-end" }
const _hoisted_105 = { class: "contacts-header" }
const _hoisted_106 = { class: "rtitle normalcase semibold mr-4" }
const _hoisted_107 = {
  key: 0,
  class: "link-contact-dropdown"
}
const _hoisted_108 = { class: "contact-card-icon" }
const _hoisted_109 = {
  key: 0,
  class: "contact-card-content"
}
const _hoisted_110 = {
  class: "is-flex",
  style: {"height":"20px","margin-right":"10px"}
}
const _hoisted_111 = { class: "contact-card-name" }
const _hoisted_112 = { key: 0 }
const _hoisted_113 = ["href"]
const _hoisted_114 = {
  key: 0,
  class: "contact-card-position-title"
}
const _hoisted_115 = { class: "has-text-black smalltext" }
const _hoisted_116 = { class: "has-text-black smalltext ml-2" }
const _hoisted_117 = {
  key: 3,
  class: "has-text-black smalltext"
}
const _hoisted_118 = ["href"]
const _hoisted_119 = { class: "contact-card-content edit" }
const _hoisted_120 = {
  key: 0,
  class: "has-text-danger smalltext",
  style: {"margin-bottom":"1.2rem"}
}
const _hoisted_121 = {
  key: 1,
  class: "has-text-danger smalltext uppercase"
}
const _hoisted_122 = {
  key: 2,
  class: "has-text-danger smalltext",
  style: {"margin-bottom":"1.2rem"}
}
const _hoisted_123 = {
  class: "edit-contact-inputs",
  style: {"margin-top":"4px"}
}
const _hoisted_124 = {
  class: "edit-contact-inputs",
  style: {"margin-bottom":"12px"}
}
const _hoisted_125 = { class: "edit-contact-inputs" }
const _hoisted_126 = { class: "edit-contact-inputs" }
const _hoisted_127 = { class: "edit-contact-inputs" }
const _hoisted_128 = { class: "edit-contact-inputs primary-contact-checkbox" }
const _hoisted_129 = { class: "buttons is-justify-content-flex-end" }
const _hoisted_130 = {
  key: 2,
  class: "contact-card-buttons"
}
const _hoisted_131 = ["onClick"]
const _hoisted_132 = ["onClick"]
const _hoisted_133 = ["onClick"]
const _hoisted_134 = {
  key: 1,
  class: "contact-card is-relative"
}
const _hoisted_135 = { class: "contact-card-icon" }
const _hoisted_136 = { class: "contact-card-content edit" }
const _hoisted_137 = {
  key: 0,
  class: "has-text-danger smalltext",
  style: {"margin-bottom":"1.2rem"}
}
const _hoisted_138 = {
  key: 1,
  class: "has-text-danger smalltext uppercase"
}
const _hoisted_139 = {
  key: 2,
  class: "has-text-danger smalltext",
  style: {"margin-bottom":"1.2rem"}
}
const _hoisted_140 = {
  class: "edit-contact-inputs",
  style: {"margin-top":"4px"}
}
const _hoisted_141 = {
  class: "edit-contact-inputs",
  style: {"margin-bottom":"12px"}
}
const _hoisted_142 = { class: "edit-contact-inputs" }
const _hoisted_143 = { class: "edit-contact-inputs" }
const _hoisted_144 = { class: "edit-contact-inputs" }
const _hoisted_145 = { class: "edit-contact-inputs primary-contact-checkbox" }
const _hoisted_146 = { class: "buttons is-justify-content-flex-end" }
const _hoisted_147 = {
  key: 1,
  class: "buttons is-justify-content-flex-end"
}
const _hoisted_148 = { class: "rudolfbox" }
const _hoisted_149 = { class: "modal-inner has-text-centered" }
const _hoisted_150 = ["innerHTML"]
const _hoisted_151 = { class: "modal-text" }
const _hoisted_152 = { class: "buttons mb-0" }
const _hoisted_153 = { class: "rudolfbox" }
const _hoisted_154 = { class: "modal-inner has-text-centered" }
const _hoisted_155 = ["innerHTML"]
const _hoisted_156 = { class: "modal-text has-text-bold" }
const _hoisted_157 = { class: "buttons mb-0" }
const _hoisted_158 = { class: "rudolfbox" }
const _hoisted_159 = { class: "modal-inner has-text-centered" }
const _hoisted_160 = ["innerHTML"]
const _hoisted_161 = { class: "buttons mb-0" }
const _hoisted_162 = { class: "rudolfbox" }
const _hoisted_163 = { class: "modal-inner" }
const _hoisted_164 = { class: "has-text-centered" }
const _hoisted_165 = { key: 0 }
const _hoisted_166 = {
  class: "modal-text",
  style: {"font-weight":"600 !important"}
}
const _hoisted_167 = {
  class: "mb-4",
  style: {"list-style-type":"disc","margin-left":"25px"}
}
const _hoisted_168 = { key: 1 }
const _hoisted_169 = {
  class: "modal-text",
  style: {"font-weight":"600 !important"}
}
const _hoisted_170 = {
  class: "mb-4",
  style: {"list-style-type":"disc","margin-left":"25px"}
}
const _hoisted_171 = { class: "buttons mb-0 is-justify-content-flex-end" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tag = _resolveComponent("b-tag")
  const _component_b_taglist = _resolveComponent("b-taglist")
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")
  const _component_b_field = _resolveComponent("b-field")
  const _component_google_maps_autocomplete = _resolveComponent("google-maps-autocomplete")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_modal = _resolveComponent("b-modal")

  return (!_ctx.appLoading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["add-edit-lead", { "rudolfbox": _ctx.type === "add" }]),
        id: "add-edit-lead"
      }, [
        (_ctx.type === "add")
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.l('add-edit-lead/add-new-lead')), 1 /* TEXT */)
              ])
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode(" ███╗   ███╗ █████╗ ██╗███╗   ██╗"),
        _createCommentVNode(" ████╗ ████║██╔══██╗██║████╗  ██║"),
        _createCommentVNode(" ██╔████╔██║███████║██║██╔██╗ ██║"),
        _createCommentVNode(" ██║╚██╔╝██║██╔══██║██║██║╚██╗██║"),
        _createCommentVNode(" ██║ ╚═╝ ██║██║  ██║██║██║ ╚████║"),
        _createCommentVNode(" ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝"),
        _createCommentVNode(""),
        _createCommentVNode(" Main details (company details)"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
        _createCommentVNode(""),
        _createCommentVNode(" View mode"),
        _createCommentVNode(""),
        _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString($options.lead.name), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.l('add-edit-lead/main-details-column-header')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.l('add-edit-lead/additional-details-column-header')), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.settingsStore.label("address")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_10, _toDisplayString($options.lead.address), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.l('add-edit-lead/field-created')) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$dayjs($options.lead.creation_timestamp).format('llll')), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.settingsStore.label("addressmisc")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_14, _toDisplayString($options.lead.addressmisc), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.settingsStore.label("projecttype")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_16, _toDisplayString($options.lead.projecttype), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.settingsStore.label("project")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_18, [
                _createTextVNode(_toDisplayString($options.lead.project) + " ", 1 /* TEXT */),
                ($options.lead.project && $options.lead.project_isparent === true)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, "(" + _toDisplayString(_ctx.l('add-edit-lead/hq-parent')) + ")", 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.settingsStore.label("customertype")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_21, _toDisplayString($options.lead.customertype), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.l('add-edit-lead/field-phonenumbers')) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_23, _toDisplayString(Array.isArray($options.lead.phoneNumbers) ? $options.lead.phoneNumbers.join(', ') : ''), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.settingsStore.label("region")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_25, _toDisplayString($options.lead.region), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.settingsStore.label("email")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_27, [
                ($options.lead.email)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "mailto:" + $options.lead.email
                    }, _toDisplayString($options.lead.email), 9 /* TEXT, PROPS */, _hoisted_28))
                  : _createCommentVNode("v-if", true)
              ]),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.settingsStore.label("zone1")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_30, _toDisplayString($options.lead.zone1), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.settingsStore.label("website")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_32, [
                ($options.websiteComputed)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: $options.websiteComputed,
                      target: "_blank"
                    }, _toDisplayString($options.lead.website), 9 /* TEXT, PROPS */, _hoisted_33))
                  : _createCommentVNode("v-if", true)
              ]),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.settingsStore.label("zone2")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_35, _toDisplayString($options.lead.zone2), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", null, [
                  _createVNode(_component_b_icon, {
                    icon: "linkedin",
                    "custom-size": "mdi-18px",
                    type: "is-light"
                  })
                ]),
                _cache[74] || (_cache[74] = _createElementVNode("div", null, "LinkedIn:", -1 /* HOISTED */))
              ]),
              _createElementVNode("div", _hoisted_37, [
                ($options.lead.linkedin)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: $options.lead.linkedin,
                      title: "LinkedIn",
                      target: "_blank"
                    }, _toDisplayString($options.lead.linkedin), 9 /* TEXT, PROPS */, _hoisted_38))
                  : _createCommentVNode("v-if", true)
              ]),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.settingsStore.label("zone3")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_40, _toDisplayString($options.lead.zone3), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _cache[75] || (_cache[75] = _createElementVNode("div", { class: "leadfield" }, null, -1 /* HOISTED */)),
              _cache[76] || (_cache[76] = _createElementVNode("div", { class: "leadvalue" }, null, -1 /* HOISTED */)),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.settingsStore.label("zone4")) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_42, _toDisplayString($options.lead.zone4), 1 /* TEXT */),
              _createCommentVNode(" -----"),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.l('add-edit-lead/field-comment')) + ":", 1 /* TEXT */),
                ($options.lead.freeTextComment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString($options.lead.freeTextComment), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _createCommentVNode(" -----"),
              (Array.isArray($options.lead.tags) && $options.lead.tags.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                    _createVNode(_component_b_taglist, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.lead.tags, (tag, idx) => {
                          return (_openBlock(), _createBlock(_component_b_tag, {
                            key: "lead_tag_" + idx
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tag), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_47, [
              _cache[77] || (_cache[77] = _createElementVNode("div", { class: "lead-preview-map" }, [
                _createElementVNode("div", { id: "lead-preview-map-component" })
              ], -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, _toDisplayString($options.lead.address), 1 /* TEXT */)
              ])
            ])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, !_ctx.editMode]
        ]),
        _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
        _createCommentVNode(""),
        _createCommentVNode(" Edit mode"),
        _createCommentVNode(""),
        _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
        _withDirectives(_createElementVNode("div", _hoisted_50, [
          _createElementVNode("div", _hoisted_51, [
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.l('add-edit-lead/main-details-column-header')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.l('add-edit-lead/additional-details-column-header')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_55, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("leadname") + " *",
                  expanded: "",
                  type: { "is-danger": _ctx.leadNameError },
                  message: _ctx.leadNameError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_autocomplete, {
                      modelValue: _ctx.leadName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.leadName) = $event)),
                      data: $options.leadNameOptions,
                      clearable: "",
                      "open-on-focus": true,
                      onFocus: _cache[1] || (_cache[1] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue", "data"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"])
              ]),
              _createElementVNode("div", _hoisted_56, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("projecttype"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_autocomplete, {
                      modelValue: _ctx.leadProjecttype,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.leadProjecttype) = $event)),
                      data: $options.projectTypeOptions,
                      clearable: "",
                      "open-on-focus": true,
                      onFocus: _cache[3] || (_cache[3] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue", "data"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_57, [
                ($props.googleMapsLoaded)
                  ? (_openBlock(), _createBlock(_component_google_maps_autocomplete, {
                      key: 0,
                      class: "lead-location-autocomplete",
                      ref: "addressAutocomplete",
                      label: _ctx.settingsStore.label("address"),
                      placeholder: "",
                      id: "lead-location",
                      onPlacechanged: $options.leadAddressAutocomplete,
                      "required-error": _ctx.leadLocationError,
                      onFocus: _cache[4] || (_cache[4] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["label", "onPlacechanged", "required-error"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("customertype"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_autocomplete, {
                      modelValue: _ctx.leadCustomertype,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.leadCustomertype) = $event)),
                      data: $options.customerTypeOptions,
                      clearable: "",
                      "open-on-focus": true,
                      onFocus: _cache[6] || (_cache[6] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue", "data"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_59, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("addressmisc"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadAdditionalAddress,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.leadAdditionalAddress) = $event)),
                      onFocus: _cache[8] || (_cache[8] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_60, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("region"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadRegion,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.leadRegion) = $event)),
                      onFocus: _cache[10] || (_cache[10] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_61, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("project"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_autocomplete, {
                      modelValue: _ctx.leadProject,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.leadProject) = $event)),
                      data: $options.belongsToOptions,
                      icon: "format-list-checkbox",
                      clearable: "",
                      "open-on-focus": true,
                      onFocus: _cache[12] || (_cache[12] = $event => (_ctx.mainDetailsModified = true)),
                      onInput: _cache[13] || (_cache[13] = $event => ($options.checkProjectParent(_ctx.leadIsProjectParent)))
                    }, null, 8 /* PROPS */, ["modelValue", "data"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_62, [
                _createVNode(_component_b_field, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_checkbox, {
                      modelValue: _ctx.leadIsProjectParent,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.leadIsProjectParent) = $event)),
                      type: "is-black",
                      onInput: _cache[15] || (_cache[15] = (value) => { $options.checkProjectParent(value); _ctx.mainDetailsModified = true }),
                      disabled: _ctx.leadProject ? false : true
                    }, {
                      default: _withCtx(() => _cache[78] || (_cache[78] = [
                        _createTextVNode("Headquarter/parent company")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _createElementVNode("div", _hoisted_63, [
                _createVNode(_component_b_field, {
                  label: "Phone number/s",
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createCommentVNode(" 'autocomplete' is a hack here to make the taginput require enter/tab keypress"),
                    _createCommentVNode(" to complete the phonenumber, otherwise pasting in a number, then going to another"),
                    _createCommentVNode(" browser tab, and going back will not allow you to input to the pasted number anymore"),
                    _createVNode(_component_b_taginput, {
                      modelValue: _ctx.leadPhoneNumbers,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.leadPhoneNumbers) = $event)),
                      ellipsis: "",
                      autocomplete: "",
                      "allow-new": true,
                      onFocus: _cache[17] || (_cache[17] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _createElementVNode("div", _hoisted_64, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("zone1"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadWorkzone1,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.leadWorkzone1) = $event)),
                      onFocus: _cache[19] || (_cache[19] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_65, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("email"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadEmail,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.leadEmail) = $event)),
                      onFocus: _cache[21] || (_cache[21] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_66, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("zone2"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadWorkzone2,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.leadWorkzone2) = $event)),
                      onFocus: _cache[23] || (_cache[23] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_67, [
                _createVNode(_component_b_field, {
                  class: "website-input",
                  label: _ctx.settingsStore.label("website"),
                  expanded: "",
                  type: { "is-danger": _ctx.leadWebsiteError }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadWebsite,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.leadWebsite) = $event)),
                      onFocus: _cache[25] || (_cache[25] = $event => (_ctx.mainDetailsModified = true)),
                      icon: "web"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type"])
              ]),
              _createElementVNode("div", _hoisted_68, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("zone3"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadWorkzone3,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.leadWorkzone3) = $event)),
                      onFocus: _cache[27] || (_cache[27] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_69, [
                _createVNode(_component_b_field, {
                  class: "linkedin-input",
                  label: "LinkedIn",
                  expanded: "",
                  type: { "is-danger": _ctx.leadLinkedInError }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadLinkedIn,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.leadLinkedIn) = $event)),
                      icon: "linkedin",
                      onFocus: _cache[29] || (_cache[29] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["type"])
              ]),
              _createElementVNode("div", _hoisted_70, [
                _createVNode(_component_b_field, {
                  label: _ctx.settingsStore.label("zone4"),
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: _ctx.leadWorkzone4,
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.leadWorkzone4) = $event)),
                      onFocus: _cache[31] || (_cache[31] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_71, [
                _createVNode(_component_b_field, {
                  class: "mb-5",
                  label: _ctx.l("add-edit-lead/field-comment"),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      id: "freeTextCommentField",
                      modelValue: _ctx.leadFreeTextComment,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.leadFreeTextComment) = $event)),
                      type: "textarea",
                      expanded: "",
                      rows: "2",
                      "custom-class": "has-fixed-size is-clipped",
                      onInput: _cache[33] || (_cache[33] = (e) => { e.target.style.height = "";e.target.style.height = e.target.scrollHeight + "px" })
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _createElementVNode("div", _hoisted_72, [
                _createVNode(_component_b_field, {
                  class: "mb-5",
                  label: _ctx.settingsStore.label("tags"),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_taginput, {
                      modelValue: _ctx.leadTags,
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.leadTags) = $event)),
                      ellipsis: "",
                      autocomplete: "",
                      data: _ctx.filteredTags,
                      onTyping: $options.getFilteredTags,
                      "allow-new": true,
                      "open-on-focus": true,
                      icon: "label",
                      placeholder: _ctx.l("generic/add-a-tag"),
                      onFocus: _cache[35] || (_cache[35] = $event => (_ctx.mainDetailsModified = true))
                    }, null, 8 /* PROPS */, ["modelValue", "data", "onTyping", "placeholder"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ])
            ]),
            _createElementVNode("div", _hoisted_73, [
              _cache[79] || (_cache[79] = _createElementVNode("div", { class: "lead-location-map" }, [
                _createElementVNode("div", { id: "lead-location-map-component" })
              ], -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_74, [
                (_ctx.type === "add")
                  ? (_openBlock(), _createElementBlock("div", _hoisted_75, _toDisplayString(_ctx.leadFormattedAddress), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (_ctx.type === "edit")
                  ? (_openBlock(), _createElementBlock("div", _hoisted_76, _toDisplayString($options.lead.address), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ])
          ]),
          (_ctx.type === "edit")
            ? (_openBlock(), _createElementBlock("div", _hoisted_77, [
                _createVNode(_component_b_button, {
                  class: "mr-3",
                  type: "is-light",
                  rounded: "",
                  onClick: _cache[36] || (_cache[36] = $event => ($options.cancelEdit())),
                  disabled: _ctx.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"]),
                _createVNode(_component_b_button, {
                  type: "is-primary",
                  rounded: "",
                  onClick: _cache[37] || (_cache[37] = $event => ($options.checkLeadData())),
                  loading: _ctx.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["loading"])
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_b_loading, {
            "is-full-page": false,
            modelValue: _ctx.loading,
            "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => ((_ctx.loading) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.editMode]
        ]),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode(" ██████╗  ██████╗  ██████╗    ██╗     ██╗███╗   ██╗██╗  ██╗███████╗"),
        _createCommentVNode(" ██╔══██╗██╔═══██╗██╔════╝    ██║     ██║████╗  ██║██║ ██╔╝██╔════╝"),
        _createCommentVNode(" ██║  ██║██║   ██║██║         ██║     ██║██╔██╗ ██║█████╔╝ ███████╗"),
        _createCommentVNode(" ██║  ██║██║   ██║██║         ██║     ██║██║╚██╗██║██╔═██╗ ╚════██║"),
        _createCommentVNode(" ██████╔╝╚██████╔╝╚██████╗    ███████╗██║██║ ╚████║██║  ██╗███████║"),
        _createCommentVNode(" ╚═════╝  ╚═════╝  ╚═════╝    ╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝"),
        _createCommentVNode(""),
        _createCommentVNode(" Document links"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createElementVNode("div", _hoisted_78, _toDisplayString(_ctx.l('add-edit-lead/document-links-title')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_79, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.leadDocumentLinks, (documentLink) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "document-link-card is-relative",
              key: "document_link_card_" + documentLink._id
            }, [
              _createElementVNode("div", _hoisted_80, [
                (documentLink.type === "csv")
                  ? (_openBlock(), _createElementBlock("img", _hoisted_81))
                  : (documentLink.type === "doc")
                    ? (_openBlock(), _createElementBlock("img", _hoisted_82))
                    : (documentLink.type === "image")
                      ? (_openBlock(), _createElementBlock("img", _hoisted_83))
                      : (documentLink.type === "pdf")
                        ? (_openBlock(), _createElementBlock("img", _hoisted_84))
                        : (documentLink.type === "ppt")
                          ? (_openBlock(), _createElementBlock("img", _hoisted_85))
                          : (documentLink.type === "xls")
                            ? (_openBlock(), _createElementBlock("img", _hoisted_86))
                            : (documentLink.type === "zip")
                              ? (_openBlock(), _createElementBlock("img", _hoisted_87))
                              : (_openBlock(), _createElementBlock("img", _hoisted_88))
              ]),
              _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
              _createCommentVNode(""),
              _createCommentVNode(" Document link card, VIEW MODE"),
              _createCommentVNode(""),
              _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
              (!_ctx.documentLinkIdsInEdit.includes(documentLink._id))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "document-link-card-content is-clickable",
                    onClick: $event => ($options.openLinkNewTab(documentLink.url))
                  }, [
                    _createElementVNode("div", _hoisted_90, _toDisplayString(documentLink.text), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_91, _toDisplayString(documentLink.url), 1 /* TEXT */)
                  ], 8 /* PROPS */, _hoisted_89))
                : _createCommentVNode("v-if", true),
              (_ctx.authStore.user.role !== "basic" && !_ctx.documentLinkIdsInEdit.includes(documentLink._id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
                    _createElementVNode("div", {
                      class: "is-clickable",
                      onClick: $event => ($options.editDocumentLink(documentLink))
                    }, [
                      _createVNode(_component_b_tooltip, {
                        type: "is-white",
                        position: "is-left",
                        delay: 1000,
                        label: _ctx.l("add-edit-lead/edit-doc-link-tooltip")
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_icon, {
                            icon: "pencil-outline",
                            "custom-size": "mdi-18px"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["label"])
                    ], 8 /* PROPS */, _hoisted_93),
                    _createElementVNode("div", {
                      class: "is-clickable",
                      onClick: _withModifiers($event => (_ctx.type === "add" ? $options.deleteDocumentLink(documentLink) : $options.deleteDocumentLinkConfirmModal(documentLink._id)), ["stop"])
                    }, [
                      _createVNode(_component_b_tooltip, {
                        type: "is-white",
                        position: "is-left",
                        delay: 1000,
                        label: _ctx.l("add-edit-lead/remove-doc-link-tooltip")
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_icon, {
                            icon: "delete-outline",
                            "custom-size": "mdi-18px"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["label"])
                    ], 8 /* PROPS */, _hoisted_94)
                  ]))
                : (_ctx.documentLinkIdsInEdit.includes(documentLink._id))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
                      _createCommentVNode(""),
                      _createCommentVNode(" Document link card, EDIT MODE"),
                      _createCommentVNode(""),
                      _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
                      _createElementVNode("div", _hoisted_95, [
                        _createElementVNode("div", _hoisted_96, [
                          _createVNode(_component_b_field, {
                            label: "Text",
                            "label-position": "on-border",
                            "custom-class": "is-small",
                            expanded: "",
                            type: { "is-danger": _ctx.documentLinksInEdit[documentLink._id].textError },
                            message: { "* Required": _ctx.documentLinksInEdit[documentLink._id].textError }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input, {
                                size: "is-small",
                                placeholder: "Link description",
                                autocomplete: "off",
                                autocorrect: "off",
                                autocapitalize: "off",
                                spellcheck: "off",
                                value: _ctx.documentLinksInEdit[documentLink._id].text,
                                onInput: $event => (_ctx.documentLinksInEdit[documentLink._id].text = $event.target.value)
                              }, null, 8 /* PROPS */, ["value", "onInput"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "message"])
                        ]),
                        _createElementVNode("div", _hoisted_97, [
                          _createVNode(_component_b_button, {
                            class: "mr-3",
                            type: "is-light",
                            size: "is-small",
                            onClick: $event => ($options.cancelEditDocumentLink(documentLink._id))
                          }, {
                            default: _withCtx(() => _cache[80] || (_cache[80] = [
                              _createTextVNode("Cancel")
                            ])),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                          _createVNode(_component_b_button, {
                            type: "is-primary",
                            size: "is-small",
                            onClick: $event => ($options.saveDocumentLink(documentLink._id))
                          }, {
                            default: _withCtx(() => _cache[81] || (_cache[81] = [
                              _createTextVNode("Save")
                            ])),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                        ])
                      ])
                    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          _createCommentVNode(""),
          _createCommentVNode(" Add new contact PLUS (+) card"),
          _createCommentVNode(""),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          (!_ctx.newDocumentLinkCardActive && _ctx.authStore.user?.role !== "basic")
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "new-document-link-card",
                onClick: _cache[39] || (_cache[39] = $event => ($options.openNewDocumentLinkCard()))
              }, [
                _createElementVNode("div", null, [
                  _createVNode(_component_b_icon, {
                    icon: "plus-box-outline",
                    "custom-size": "mdi-36px"
                  })
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          _createCommentVNode(""),
          _createCommentVNode(" New contact card"),
          _createCommentVNode(""),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          (_ctx.newDocumentLinkCardActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_98, [
                _cache[82] || (_cache[82] = _createElementVNode("div", { class: "document-link-card-icon" }, [
                  _createElementVNode("img", {
                    src: _imports_7,
                    style: {"height":"40px"}
                  })
                ], -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_99, [
                  (_ctx.newDocumentLinkOtherError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_100, "Server failure while attempting to save this document link."))
                    : _createCommentVNode("v-if", true),
                  (_ctx.newDocumentLinkOtherError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_101, "Try again or contact Rudolf support if this problem persists."))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_102, [
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/text"),
                      "label-position": "on-border",
                      "custom-class": "is-small",
                      expanded: "",
                      type: { "is-danger": _ctx.newDocumentLinkTextError },
                      message: _ctx.newDocumentLinkTextError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("add-edit-lead/link-desc-placeholder"),
                          autocomplete: "off",
                          autocorrect: "off",
                          autocapitalize: "off",
                          spellcheck: "off",
                          modelValue: _ctx.newDocumentLinkText,
                          "onUpdate:modelValue": _cache[40] || (_cache[40] = $event => ((_ctx.newDocumentLinkText) = $event))
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "type", "message"])
                  ]),
                  _createElementVNode("div", _hoisted_103, [
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/url"),
                      "label-position": "on-border",
                      "custom-class": "is-small",
                      expanded: "",
                      type: { "is-danger": _ctx.newDocumentLinkUrlError },
                      message: _ctx.newDocumentLinkUrlError ? _ctx.l("add-edit-lead/invalid-url") : ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("add-edit-lead/url-placeholder"),
                          autocomplete: "off",
                          autocorrect: "off",
                          autocapitalize: "off",
                          spellcheck: "off",
                          modelValue: _ctx.newDocumentLinkUrl,
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = $event => ((_ctx.newDocumentLinkUrl) = $event))
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "type", "message"])
                  ]),
                  _createElementVNode("div", _hoisted_104, [
                    _createVNode(_component_b_button, {
                      class: "mr-3",
                      type: "is-light",
                      size: "is-small",
                      onClick: _cache[42] || (_cache[42] = $event => ($options.closeNewDocumentLinkCard())),
                      disabled: _ctx.documentLinksLoading
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled"]),
                    _createVNode(_component_b_button, {
                      type: "is-primary",
                      size: "is-small",
                      onClick: _cache[43] || (_cache[43] = $event => ($options.addNewDocumentLink())),
                      loading: _ctx.documentLinksLoading
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('generic/add')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["loading"])
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_b_loading, {
            "is-full-page": false,
            modelValue: _ctx.documentLinksLoading,
            "onUpdate:modelValue": _cache[44] || (_cache[44] = $event => ((_ctx.documentLinksLoading) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode("  ██████╗ ██████╗ ███╗   ██╗████████╗ █████╗  ██████╗████████╗"),
        _createCommentVNode(" ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔════╝╚══██╔══╝"),
        _createCommentVNode(" ██║     ██║   ██║██╔██╗ ██║   ██║   ███████║██║        ██║"),
        _createCommentVNode(" ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██║██║        ██║"),
        _createCommentVNode(" ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║╚██████╗   ██║"),
        _createCommentVNode("  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝   ╚═╝"),
        _createCommentVNode(""),
        _createCommentVNode(" Contact details"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createElementVNode("div", _hoisted_105, [
          _createElementVNode("div", _hoisted_106, _toDisplayString(_ctx.l('add-edit-lead/contacts-header')), 1 /* TEXT */),
          (_ctx.authStore.user?.role !== "basic")
            ? (_openBlock(), _createElementBlock("div", _hoisted_107, [
                _createVNode(_component_b_autocomplete, {
                  modelValue: _ctx.existingContactsSearchStr,
                  "onUpdate:modelValue": _cache[45] || (_cache[45] = $event => ((_ctx.existingContactsSearchStr) = $event)),
                  placeholder: _ctx.l("add-edit-lead/link-contact") + "...",
                  icon: "link-variant",
                  size: "is-small",
                  "open-on-focus": true,
                  "clear-on-select": true,
                  data: $options.filteredContacts,
                  field: "fullName",
                  onSelect: _cache[46] || (_cache[46] = contact => $options.linkContact(contact._id)),
                  disabled: _ctx.contactsLoading
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "data", "disabled"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["contact-cards is-relative", { "mb-4": _ctx.type === "add" }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.leadContacts, (contact) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["contact-card is-relative", { "primary-contact": $options.lead.primaryContact === contact._id || this.newLeadPrimaryContact === contact._id }]),
              key: "contact_card_" + contact._id
            }, [
              _createElementVNode("div", _hoisted_108, [
                ($options.lead.primaryContact === contact._id || this.newLeadPrimaryContact === contact._id)
                  ? (_openBlock(), _createBlock(_component_b_tooltip, {
                      key: 0,
                      class: "is-clickable",
                      type: "is-white",
                      position: "is-right",
                      label: _ctx.l("add-edit-lead/primary-contact")
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon, { icon: "account-star" })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]))
                  : (_openBlock(), _createBlock(_component_b_icon, {
                      key: 1,
                      icon: "account"
                    }))
              ]),
              _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
              _createCommentVNode(""),
              _createCommentVNode(" Contact card, VIEW MODE"),
              _createCommentVNode(""),
              _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
              (!_ctx.contactIdsInEdit.includes(contact._id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_109, [
                    _createElementVNode("div", _hoisted_110, [
                      _createElementVNode("div", _hoisted_111, _toDisplayString(contact.firstname) + " " + _toDisplayString(contact.lastname), 1 /* TEXT */),
                      (contact.linkedin)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_112, [
                            _createElementVNode("a", {
                              href: contact.linkedin,
                              target: "_blank",
                              title: "LinkedIn",
                              style: {"display":"inline-block","width":"16px","margin":"0 0 1px 7px"}
                            }, _cache[83] || (_cache[83] = [
                              _createElementVNode("img", {
                                src: _imports_8,
                                width: "16"
                              }, null, -1 /* HOISTED */)
                            ]), 8 /* PROPS */, _hoisted_113)
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    (contact.title)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_114, _toDisplayString(contact.title), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("span", _hoisted_115, _toDisplayString(contact.telephone), 1 /* TEXT */),
                    (contact.telephone)
                      ? (_openBlock(), _createBlock(_component_b_icon, {
                          key: 1,
                          icon: "content-copy",
                          "custom-class": "clipboard-copy-icon is-clickable",
                          onClick: $event => ($options.copyToClipboard(contact.telephone))
                        }, null, 8 /* PROPS */, ["onClick"]))
                      : _createCommentVNode("v-if", true),
                    (contact.telephone2)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("span", _hoisted_116, _toDisplayString(contact.telephone2), 1 /* TEXT */),
                          (contact.telephone2)
                            ? (_openBlock(), _createBlock(_component_b_icon, {
                                key: 0,
                                icon: "content-copy",
                                "custom-class": "clipboard-copy-icon is-clickable",
                                onClick: $event => ($options.copyToClipboard(contact.telephone2))
                              }, null, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      : _createCommentVNode("v-if", true),
                    _cache[84] || (_cache[84] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                    (contact.email)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_117, [
                          _createElementVNode("a", {
                            href: "mailto:" + contact.email
                          }, _toDisplayString(contact.email), 9 /* TEXT, PROPS */, _hoisted_118)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (contact.email)
                      ? (_openBlock(), _createBlock(_component_b_icon, {
                          key: 4,
                          icon: "content-copy",
                          "custom-class": "clipboard-copy-icon is-clickable",
                          onClick: $event => ($options.copyToClipboard(contact.email))
                        }, null, 8 /* PROPS */, ["onClick"]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : (_ctx.contactIdsInEdit.includes(contact._id))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
                      _createCommentVNode(""),
                      _createCommentVNode(" Contact card, EDIT MODE"),
                      _createCommentVNode(""),
                      _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
                      _createElementVNode("div", _hoisted_119, [
                        (_ctx.contactsInEdit[contact._id].duplicateContactError)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_120, _toDisplayString(_ctx.l('contacts/already-exists')), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        (_ctx.contactsInEdit[contact._id].otherError)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_121, _toDisplayString(_ctx.l('add-edit-lead/contacts-server-error1')), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        (_ctx.contactsInEdit[contact._id].otherError)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_122, _toDisplayString(_ctx.l('add-edit-lead/contacts-server-error2')), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_123, [
                          _createVNode(_component_b_field, { grouped: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_field, {
                                label: _ctx.l("generic/first-name"),
                                "label-position": "on-border",
                                "custom-class": "is-small",
                                style: {"flex":"50%"},
                                type: { "is-danger": _ctx.contactsInEdit[contact._id].firstNameError || _ctx.contactsInEdit[contact._id].duplicateContactError },
                                message: _ctx.contactsInEdit[contact._id].firstNameError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_input, {
                                    size: "is-small",
                                    placeholder: _ctx.l("generic/first-name"),
                                    autocomplete: "off",
                                    autocorrect: "off",
                                    autocapitalize: "off",
                                    spellcheck: "off",
                                    value: _ctx.contactsInEdit[contact._id].firstName,
                                    onInput: $event => (_ctx.contactsInEdit[contact._id].firstName = $event.target.value)
                                  }, null, 8 /* PROPS */, ["placeholder", "value", "onInput"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type", "message"]),
                              _createVNode(_component_b_field, {
                                label: _ctx.l("generic/last-name"),
                                "label-position": "on-border",
                                autocomplete: "off",
                                autocorrect: "off",
                                autocapitalize: "off",
                                spellcheck: "off",
                                "custom-class": "is-small",
                                style: {"flex":"50%"},
                                type: { "is-danger": _ctx.contactsInEdit[contact._id].lastNameError || _ctx.contactsInEdit[contact._id].duplicateContactError },
                                message: _ctx.contactsInEdit[contact._id].lastNameError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_input, {
                                    size: "is-small",
                                    placeholder: _ctx.l("generic/last-name"),
                                    value: _ctx.contactsInEdit[contact._id].lastName,
                                    onInput: $event => (_ctx.contactsInEdit[contact._id].lastName = $event.target.value)
                                  }, null, 8 /* PROPS */, ["placeholder", "value", "onInput"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type", "message"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _createElementVNode("div", _hoisted_124, [
                          _createVNode(_component_b_field, {
                            label: _ctx.l("contacts/title"),
                            "label-position": "on-border",
                            "custom-class": "is-small",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input, {
                                size: "is-small",
                                placeholder: _ctx.l("contacts/title"),
                                autocomplete: "off",
                                autocorrect: "off",
                                autocapitalize: "off",
                                spellcheck: "off",
                                value: _ctx.contactsInEdit[contact._id].title,
                                onInput: $event => (_ctx.contactsInEdit[contact._id].title = $event.target.value)
                              }, null, 8 /* PROPS */, ["placeholder", "value", "onInput"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                        ]),
                        _createElementVNode("div", _hoisted_125, [
                          _createVNode(_component_b_field, { grouped: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_field, {
                                label: _ctx.l("contacts/phone1"),
                                "label-position": "on-border",
                                "custom-class": "is-small",
                                expanded: "",
                                type: { "is-danger": _ctx.contactsInEdit[contact._id].phoneNumberError || _ctx.contactsInEdit[contact._id].duplicateContactError },
                                message: _ctx.contactsInEdit[contact._id].phoneNumberError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_input, {
                                    size: "is-small",
                                    placeholder: _ctx.l("contacts/phone1"),
                                    autocomplete: "off",
                                    autocorrect: "off",
                                    autocapitalize: "off",
                                    spellcheck: "off",
                                    value: _ctx.contactsInEdit[contact._id].telephone,
                                    onInput: $event => (_ctx.contactsInEdit[contact._id].telephone = $event.target.value)
                                  }, null, 8 /* PROPS */, ["placeholder", "value", "onInput"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type", "message"]),
                              _createVNode(_component_b_field, {
                                label: _ctx.l("contacts/phone2"),
                                "label-position": "on-border",
                                "custom-class": "is-small",
                                expanded: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_input, {
                                    size: "is-small",
                                    placeholder: _ctx.l("contacts/phone2"),
                                    autocomplete: "off",
                                    autocorrect: "off",
                                    autocapitalize: "off",
                                    spellcheck: "off",
                                    value: _ctx.contactsInEdit[contact._id].telephone2,
                                    onInput: $event => (_ctx.contactsInEdit[contact._id].telephone2 = $event.target.value)
                                  }, null, 8 /* PROPS */, ["placeholder", "value", "onInput"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _createElementVNode("div", _hoisted_126, [
                          _createVNode(_component_b_field, {
                            label: _ctx.l("generic/email"),
                            "label-position": "on-border",
                            "custom-class": "is-small",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input, {
                                size: "is-small",
                                placeholder: "example@email.com",
                                autocomplete: "off",
                                autocorrect: "off",
                                autocapitalize: "off",
                                spellcheck: "off",
                                modelValue: _ctx.contactsInEdit[contact._id].email,
                                "onUpdate:modelValue": $event => ((_ctx.contactsInEdit[contact._id].email) = $event)
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                        ]),
                        _createElementVNode("div", _hoisted_127, [
                          _createVNode(_component_b_field, {
                            label: "LinkedIn",
                            "label-position": "on-border",
                            "custom-class": "is-small",
                            expanded: "",
                            type: { "is-danger": _ctx.contactsInEdit[contact._id].linkedInError },
                            message: _ctx.contactsInEdit[contact._id].linkedInError ? _ctx.l("contacts/invalid-linkedin-url") : ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input, {
                                icon: "linkedin",
                                size: "is-small",
                                autocomplete: "off",
                                autocorrect: "off",
                                autocapitalize: "off",
                                spellcheck: "off",
                                value: _ctx.contactsInEdit[contact._id].linkedIn,
                                onInput: $event => (_ctx.contactsInEdit[contact._id].linkedIn = $event.target.value)
                              }, null, 8 /* PROPS */, ["value", "onInput"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "message"])
                        ]),
                        _createElementVNode("div", _hoisted_128, [
                          _createVNode(_component_b_field, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_checkbox, {
                                modelValue: _ctx.contactsInEdit[contact._id].primary,
                                "onUpdate:modelValue": $event => ((_ctx.contactsInEdit[contact._id].primary) = $event),
                                type: "is-black"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.l('add-edit-lead/make-primary-contact')), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _createElementVNode("div", _hoisted_129, [
                          _createVNode(_component_b_button, {
                            class: "mr-3",
                            type: "is-light",
                            size: "is-small",
                            onClick: $event => ($options.cancelEditContact(contact._id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                          _createVNode(_component_b_button, {
                            type: "is-primary",
                            size: "is-small",
                            onClick: $event => ($options.saveContact(contact._id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                        ])
                      ])
                    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
              (_ctx.authStore.user.role !== "basic" && !_ctx.contactIdsInEdit.includes(contact._id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_130, [
                    _createElementVNode("div", {
                      class: "is-clickable",
                      onClick: $event => ($options.editContact(contact))
                    }, [
                      _createVNode(_component_b_tooltip, {
                        multilined: "",
                        type: "is-white",
                        position: "is-left",
                        delay: 1000,
                        label: "Edit contact. NOTE: Will modify contact in all other leads aswell."
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_icon, {
                            icon: "pencil-outline",
                            "custom-size": "mdi-18px"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ], 8 /* PROPS */, _hoisted_131),
                    _createElementVNode("div", {
                      class: "is-clickable",
                      onClick: $event => ($options.unlinkContact(contact._id))
                    }, [
                      _createVNode(_component_b_tooltip, {
                        multilined: "",
                        type: "is-white",
                        position: "is-left",
                        delay: 1000,
                        label: "Remove contact from this lead. Contact will still remain in Rudolf."
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_icon, {
                            icon: "link-variant-off",
                            "custom-size": "mdi-18px"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ], 8 /* PROPS */, _hoisted_132),
                    _createElementVNode("div", {
                      class: "is-clickable",
                      onClick: _withModifiers($event => ($options.deleteContactConfirmModal(contact._id)), ["stop"])
                    }, [
                      _createVNode(_component_b_tooltip, {
                        multilined: "",
                        type: "is-white",
                        position: "is-left",
                        delay: 1000,
                        label: "Permanently delete contact from all leads and Rudolf."
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_icon, {
                            icon: "delete-outline",
                            "custom-size": "mdi-18px"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ], 8 /* PROPS */, _hoisted_133)
                  ]))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */)),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          _createCommentVNode(""),
          _createCommentVNode(" Add new contact PLUS (+) card"),
          _createCommentVNode(""),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          (!_ctx.newContactCardActive && _ctx.authStore.user?.role !== "basic")
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "new-contact-card",
                onClick: _cache[47] || (_cache[47] = $event => ($options.openNewContactCard()))
              }, [
                _createElementVNode("div", null, [
                  _createVNode(_component_b_icon, {
                    icon: "plus-box-outline",
                    "custom-size": "mdi-48px"
                  })
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          _createCommentVNode(""),
          _createCommentVNode(" New contact card, edit mode"),
          _createCommentVNode(""),
          _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
          (_ctx.newContactCardActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_134, [
                _createElementVNode("div", _hoisted_135, [
                  _createVNode(_component_b_icon, { icon: "account" })
                ]),
                _createElementVNode("div", _hoisted_136, [
                  (_ctx.newContactDuplicateError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_137, _toDisplayString(_ctx.l('contacts/already-exists')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_ctx.newContactOtherError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_138, _toDisplayString(_ctx.l('add-edit-lead/contacts-server-error1')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_ctx.newContactOtherError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_139, _toDisplayString(_ctx.l('add-edit-lead/contacts-server-error2')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_140, [
                    _createVNode(_component_b_field, { grouped: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_field, {
                          label: _ctx.l("generic/first-name"),
                          "label-position": "on-border",
                          "custom-class": "is-small",
                          style: {"flex":"50%"},
                          type: { "is-danger": _ctx.newContactFirstNameError || _ctx.newContactDuplicateError },
                          message: _ctx.newContactFirstNameError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              size: "is-small",
                              placeholder: _ctx.l("generic/first-name"),
                              autocomplete: "off",
                              autocorrect: "off",
                              autocapitalize: "off",
                              spellcheck: "off",
                              modelValue: _ctx.newContactFirstName,
                              "onUpdate:modelValue": _cache[48] || (_cache[48] = $event => ((_ctx.newContactFirstName) = $event))
                            }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label", "type", "message"]),
                        _createVNode(_component_b_field, {
                          label: _ctx.l("generic/last-name"),
                          "label-position": "on-border",
                          "custom-class": "is-small",
                          style: {"flex":"50%"},
                          type: { "is-danger": _ctx.newContactLastNameError || _ctx.newContactDuplicateError },
                          message: _ctx.newContactLastNameError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              size: "is-small",
                              placeholder: _ctx.l("generic/last-name"),
                              autocomplete: "off",
                              autocorrect: "off",
                              autocapitalize: "off",
                              spellcheck: "off",
                              modelValue: _ctx.newContactLastName,
                              "onUpdate:modelValue": _cache[49] || (_cache[49] = $event => ((_ctx.newContactLastName) = $event))
                            }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label", "type", "message"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _createElementVNode("div", _hoisted_141, [
                    _createVNode(_component_b_field, {
                      label: _ctx.l("contacts/title"),
                      "label-position": "on-border",
                      "custom-class": "is-small",
                      expanded: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("contacts/title"),
                          autocomplete: "off",
                          autocorrect: "off",
                          autocapitalize: "off",
                          spellcheck: "off",
                          modelValue: _ctx.newContactTitle,
                          "onUpdate:modelValue": _cache[50] || (_cache[50] = $event => ((_ctx.newContactTitle) = $event))
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_142, [
                    _createVNode(_component_b_field, { grouped: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_field, {
                          label: _ctx.l("contacts/phone1"),
                          "label-position": "on-border",
                          "custom-class": "is-small",
                          expanded: "",
                          type: { "is-danger": _ctx.newContactPhoneNumberError || _ctx.newContactDuplicateError },
                          message: _ctx.newContactPhoneNumberError ? "* " + _ctx.l("add-edit-lead/required-field") : ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              size: "is-small",
                              placeholder: _ctx.l("contacts/phone1"),
                              autocomplete: "off",
                              autocorrect: "off",
                              autocapitalize: "off",
                              spellcheck: "off",
                              modelValue: _ctx.newContactTelephone,
                              "onUpdate:modelValue": _cache[51] || (_cache[51] = $event => ((_ctx.newContactTelephone) = $event))
                            }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label", "type", "message"]),
                        _createVNode(_component_b_field, {
                          label: _ctx.l("contacts/phone2"),
                          "label-position": "on-border",
                          "custom-class": "is-small",
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              size: "is-small",
                              placeholder: _ctx.l("contacts/phone1"),
                              autocomplete: "off",
                              autocorrect: "off",
                              autocapitalize: "off",
                              spellcheck: "off",
                              modelValue: _ctx.newContactTelephone2,
                              "onUpdate:modelValue": _cache[52] || (_cache[52] = $event => ((_ctx.newContactTelephone2) = $event))
                            }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _createElementVNode("div", _hoisted_143, [
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/email"),
                      "label-position": "on-border",
                      "custom-class": "is-small",
                      expanded: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: "example@email.com",
                          autocomplete: "off",
                          autocorrect: "off",
                          autocapitalize: "off",
                          spellcheck: "off",
                          modelValue: _ctx.newContactEmail,
                          "onUpdate:modelValue": _cache[53] || (_cache[53] = $event => ((_ctx.newContactEmail) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_144, [
                    _createVNode(_component_b_field, {
                      label: "LinkedIn",
                      "label-position": "on-border",
                      "custom-class": "is-small",
                      expanded: "",
                      type: { "is-danger": _ctx.newContactLinkedInError },
                      message: _ctx.newContactLinkedInError ? _ctx.l("contacts/invalid-linkedin-url") : ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          icon: "linkedin",
                          size: "is-small",
                          autocomplete: "off",
                          autocorrect: "off",
                          autocapitalize: "off",
                          spellcheck: "off",
                          modelValue: _ctx.newContactLinkedIn,
                          "onUpdate:modelValue": _cache[54] || (_cache[54] = $event => ((_ctx.newContactLinkedIn) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["type", "message"])
                  ]),
                  _createElementVNode("div", _hoisted_145, [
                    _createVNode(_component_b_field, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_checkbox, {
                          modelValue: _ctx.newContactPrimary,
                          "onUpdate:modelValue": _cache[55] || (_cache[55] = $event => ((_ctx.newContactPrimary) = $event)),
                          type: "is-black"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.l('add-edit-lead/make-primary-contact')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _createElementVNode("div", _hoisted_146, [
                    _createVNode(_component_b_button, {
                      class: "mr-3",
                      type: "is-light",
                      size: "is-small",
                      onClick: _cache[56] || (_cache[56] = $event => ($options.closeNewContactCard()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_button, {
                      type: "is-primary",
                      size: "is-small",
                      onClick: _cache[57] || (_cache[57] = $event => ($options.createNewContact()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('generic/create')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_b_loading, {
            "is-full-page": false,
            modelValue: _ctx.contactsLoading,
            "onUpdate:modelValue": _cache[58] || (_cache[58] = $event => ((_ctx.contactsLoading) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ], 2 /* CLASS */),
        (_ctx.type === "add")
          ? (_openBlock(), _createElementBlock("div", _hoisted_147, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[59] || (_cache[59] = $event => ($options.toggleAddNewLead())),
                disabled: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"]),
              _createVNode(_component_b_button, {
                class: "ml-5",
                type: "is-primary",
                rounded: "",
                onClick: _cache[60] || (_cache[60] = $event => ($options.checkLeadData())),
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('add-edit-lead/create-new-lead-button')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading"])
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
        _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
        _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
        _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
        _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
        _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createVNode(_component_b_modal, {
          class: "fit-content-height",
          modelValue: _ctx.deleteContactConfirmationModalActive,
          "onUpdate:modelValue": _cache[63] || (_cache[63] = $event => ((_ctx.deleteContactConfirmationModalActive) = $event)),
          width: 640,
          scroll: "keep",
          "can-cancel": [ 'escape', 'outside' ],
          "on-cancel": $options.closeDeleteContact
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_148, [
              _createElementVNode("div", _hoisted_149, [
                _createVNode(_component_b_icon, {
                  icon: "alert-circle-outline",
                  "custom-class": "modal-alert"
                }),
                _createElementVNode("p", {
                  class: "modal-text",
                  innerHTML: _ctx.l_escaped("add-edit-lead/delete-contact-confirm-text1", _ctx.contactToBeDeleted.fullName)
                }, null, 8 /* PROPS */, _hoisted_150),
                _createElementVNode("p", _hoisted_151, _toDisplayString(_ctx.l('add-edit-lead/delete-contact-confirm-text2')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_152, [
                  _createVNode(_component_b_button, {
                    type: "is-light",
                    rounded: "",
                    onClick: _cache[61] || (_cache[61] = $event => ($options.closeDeleteContact()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_button, {
                    type: "is-primary",
                    rounded: "",
                    onClick: _cache[62] || (_cache[62] = $event => ($options.deleteContact(_ctx.contactToBeDeleted))),
                    loading: _ctx.newContactLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
        _createVNode(_component_b_modal, {
          class: "fit-content-height",
          modelValue: _ctx.deleteDocumentLinkConfirmModalActive,
          "onUpdate:modelValue": _cache[66] || (_cache[66] = $event => ((_ctx.deleteDocumentLinkConfirmModalActive) = $event)),
          width: 640,
          scroll: "keep",
          "can-cancel": [ 'escape', 'outside' ],
          "on-cancel": $options.closeDeleteDocumentLink
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_153, [
              _createElementVNode("div", _hoisted_154, [
                _createVNode(_component_b_icon, {
                  icon: "alert-circle-outline",
                  "custom-class": "modal-alert"
                }),
                _createElementVNode("p", {
                  class: "modal-text",
                  innerHTML: _ctx.l_escaped("add-edit-lead/delete-doc-link-confirm-text")
                }, null, 8 /* PROPS */, _hoisted_155),
                _createElementVNode("p", _hoisted_156, "\"" + _toDisplayString(_ctx.documentLinkToBeDeleted.text) + "\"", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_157, [
                  _createVNode(_component_b_button, {
                    type: "is-light",
                    rounded: "",
                    onClick: _cache[64] || (_cache[64] = $event => ($options.closeDeleteDocumentLink()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_button, {
                    type: "is-primary",
                    rounded: "",
                    onClick: _cache[65] || (_cache[65] = $event => ($options.deleteDocumentLink(_ctx.documentLinkToBeDeleted))),
                    loading: _ctx.documentLinksLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
        _createVNode(_component_b_modal, {
          class: "fit-content-height",
          modelValue: _ctx.projectParentConfirmModalActive,
          "onUpdate:modelValue": _cache[69] || (_cache[69] = $event => ((_ctx.projectParentConfirmModalActive) = $event)),
          width: 640,
          scroll: "keep",
          "can-cancel": [ 'escape', 'outside' ],
          "on-cancel": $options.closeProjectParentConfirmModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_158, [
              _createElementVNode("div", _hoisted_159, [
                _createVNode(_component_b_icon, {
                  icon: "alert-circle-outline",
                  "custom-class": "modal-alert"
                }),
                _createElementVNode("p", {
                  class: "modal-text",
                  innerHTML: _ctx.l_escaped("add-edit-lead/change-hq-confirm-text", _ctx.leadProjectParentTask.name, _ctx.leadProjectParentTask.project)
                }, null, 8 /* PROPS */, _hoisted_160),
                _createElementVNode("div", _hoisted_161, [
                  _createVNode(_component_b_button, {
                    type: "is-light",
                    rounded: "",
                    onClick: _cache[67] || (_cache[67] = $event => ($options.closeProjectParentConfirmModal()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_button, {
                    type: "is-primary",
                    rounded: "",
                    onClick: _cache[68] || (_cache[68] = $event => ($options.closeProjectParentConfirmModal(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/proceed')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
        _createVNode(_component_b_modal, {
          class: "fit-content-height",
          modelValue: _ctx.duplicateLeadModalActive,
          "onUpdate:modelValue": _cache[73] || (_cache[73] = $event => ((_ctx.duplicateLeadModalActive) = $event)),
          width: 640,
          scroll: "keep",
          "can-cancel": [ 'escape', 'outside' ],
          "on-cancel": $options.closeDuplicateLeadModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_162, [
              _createElementVNode("div", _hoisted_163, [
                _createElementVNode("div", _hoisted_164, [
                  _createVNode(_component_b_icon, {
                    icon: "alert-circle-outline",
                    "custom-class": "modal-alert"
                  })
                ]),
                (_ctx.duplicateLeadsByName.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_165, [
                      _createElementVNode("p", _hoisted_166, _toDisplayString(_ctx.l('add-edit-lead/duplicate-leads-by-name')) + ":", 1 /* TEXT */),
                      _createElementVNode("ul", _hoisted_167, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.duplicateLeadsByName, (duplicateLead) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: "duplicate_lead_by_name_" + duplicateLead._id
                          }, _toDisplayString(duplicateLead.name), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.duplicateLeadsByLocation.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_168, [
                      _createElementVNode("p", _hoisted_169, _toDisplayString(_ctx.l('add-edit-lead/duplicate-leads-by-location')) + " (" + _toDisplayString(_ctx.leadFormattedAddress) + "):", 1 /* TEXT */),
                      _createElementVNode("ul", _hoisted_170, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.duplicateLeadsByLocation, (duplicateLead) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: "duplicate_lead_by_location_" + duplicateLead._id
                          }, _toDisplayString(duplicateLead.name), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_171, [
                  _createVNode(_component_b_button, {
                    type: "is-light",
                    rounded: "",
                    onClick: _cache[70] || (_cache[70] = $event => ($options.closeDuplicateLeadModal()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/go-back')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (_ctx.type === "add")
                    ? (_openBlock(), _createBlock(_component_b_button, {
                        key: 0,
                        type: "is-primary",
                        rounded: "",
                        onClick: _cache[71] || (_cache[71] = $event => ($options.createLead()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.l('add-edit-lead/create-anyway')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : (_ctx.type === "edit")
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 1,
                          type: "is-primary",
                          rounded: "",
                          onClick: _cache[72] || (_cache[72] = $event => ($options.saveLead()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.l('add-edit-lead/save-anyway')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                ])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "on-cancel"])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}