//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import dayjs                        from 'dayjs'
import xlsx                         from 'json-as-xlsx'

import * as appHelpers              from '../helpers.js'

import { useContactsStore }         from '../store/contacts.js'
import { useSalesStore }            from '../store/sales.js'
import { useSettingsStore }         from '../store/settings.js'



export function exportLeadsToExcel(leads, selectedColumns, leadPerContact) {
    const detailedLeads = JSON.parse(JSON.stringify(leads))

    const data = _buildJsonAsXlsxData(detailedLeads, selectedColumns, leadPerContact)

    const dateStr = dayjs().format('DD-MM-YYYY')

    let settings = {
        fileName: `rudolf-leads-export-${dateStr}`,
        extraLength: 2,
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }

    // This will also pop "Save As" -dialog
    xlsx(data, settings)
}


function _buildJsonAsXlsxData(leads, selectedColumns, leadPerContact) {
    const settingsStore = useSettingsStore()

    const columns = selectedColumns.map(selectedColumn => {
        const field = selectedColumn.field

        if(['sales_totals', 'sales_bymonth'].includes(field)) return undefined

        const column = {
            label: selectedColumn.label,
            value: field
        }

        //if(selectedColumn.field.startsWith('sales_')) column.format = '$0.00'

        return column
    }).filter(c => c !== undefined)

    columns.unshift({
        label: settingsStore.label('leadname'),
        value: 'leadname'
    })

    if(leadPerContact) {
        columns.push({ label: 'First name',     value: 'contact_firstname' })
        columns.push({ label: 'Last name',      value: 'contact_lastname' })
        columns.push({ label: 'Title',          value: 'contact_title' })
        columns.push({ label: 'Email',          value: 'contact_email' })
        columns.push({ label: 'Contact number', value: 'contact_telephone' })
        columns.push({ label: 'Mobile number',  value: 'contact_telephone2' })
    }

    const content = []

    for(const lead of leads) {
        content.push(..._buildExcelLeadRow(lead, selectedColumns, leadPerContact))

        if(!Array.isArray(lead.subleads)) continue

        for(const sublead of lead.subleads) {
            content.push(..._buildExcelLeadRow(sublead, selectedColumns, leadPerContact, true))
        }
    }

    const data = [
        {
            sheet: 'Rudolf Leads export',
            columns,
            content
        }
    ]

    return data
}


function _buildExcelLeadRow(lead, selectedColumns, leadPerContact, isSubLead = false) {
    const rows = []

    const row = {}

    for(const selectedColumn of selectedColumns) {
        const field = selectedColumn.field

        if(['sales_totals', 'sales_bymonth'].includes(field)) continue

        if(field.startsWith('question_')) {
            row[field] = _getQuestionFieldValue(lead, selectedColumn)
        } else {
            row[field] = _getLeadFieldValue(lead, field)
        }

    }

    row['leadname'] = lead.name

    if(isSubLead) row['leadname'] = `--- ${lead.name}`

    if(leadPerContact && Array.isArray(lead.contacts) && lead.contacts.length > 0) {
        for(const contact of lead.contacts) {
            const rowWithContact = JSON.parse(JSON.stringify(row))

            rowWithContact['contact_firstname']    = contact?.firstname || ''
            rowWithContact['contact_lastname']     = contact?.lastname || ''
            rowWithContact['contact_title']        = contact?.title || ''
            rowWithContact['contact_email']        = contact?.email || ''
            rowWithContact['contact_telephone']    = contact?.telephone || ''
            rowWithContact['contact_telephone2']   = contact?.telephone2 || ''

            rows.push(rowWithContact)
        }
    } else {
        rows.push(row)
    }

    return rows
}


function _getLeadFieldValue(lead, field) {
    const salesStore = useSalesStore()

    switch(field) {
        case 'leadname':    return lead.name
        case 'belongs_to':  return lead.project
        case 'sales_totals_text': {
            if(!lead.hasSales) return ''

            const sales = appHelpers.calcSales(lead, salesStore.selectedMonth)

            const month = dayjs().month(salesStore.selectedMonth).format("MMMM")

            const target    = appHelpers.toCurrency(sales.target)
            const monthly   = appHelpers.toCurrency(sales.monthly)
            const lastYear  = appHelpers.toCurrency(sales.lastYear)

            return `Target YTD (${month}): ${target}
Actual YTD (${month}): ${monthly}
Last year YTD (${month}): ${lastYear}`
        }
        case 'sales_ytd': {
            if(!lead.hasSales) return ''

            const sales = appHelpers.calcSales(lead, salesStore.selectedMonth)

            const month = dayjs().month(salesStore.selectedMonth).format("MMMM")

            const monthly = appHelpers.toCurrency(sales.monthly)

            return `${monthly} (${month})`
        }
        case 'sales_ytdvs': {
            if(!lead.hasSales) return ''

            const sales = appHelpers.calcSales(lead, salesStore.selectedMonth)

            const month = dayjs().month(salesStore.selectedMonth).format("MMMM")

            return `${sales.vsTargetPercentage}% (${month})`
        }
        case 'product':
            return lead.assignments.map(a => {
                return a.productName
            }).join(', ')
        case 'assignment':
            return lead.assignments.map(a => {
                return `${a.productName}: ${a.assigneeFullName}`
            }).join(', ')
        case 'status':
            const statuses = lead.assignments.map(a => {
                if(!a.assigneeId || !a.statusText) return null // no user assignment

                return `${a.productName}: ${a.statusText}`
            }).filter(s => s !== null)

            return statuses.join(', ')
        case 'full_address':    return lead.address
        case 'address':         return lead.streetaddress
        case 'city':            return lead.city
        case 'state':           return lead.formattedState
        case 'country':         return lead.formattedCountry
        case 'address_misc':    return lead.addressmisc
        case 'due_dates':
            const dueDates = lead.assignments.map(a => {
                if(!a.dueDate) return null // no due date

                return `${a.productName}: ${appHelpers.formatDate(a.dueDate, 'full-date')}`
            }).filter(s => s !== null)

            return dueDates.join(', ')
        case 'next_appts':
            const nextAppts = lead.assignments.map(a => {
                if(!a.nextAppt) return null // no due date

                return `${a.productName}: ${appHelpers.formatDate(a.nextAppt, 'full-datetime')}`
            }).filter(s => s !== null)

            return nextAppts.join(', ')
        case 'phone_numbers':
            if(!Array.isArray(lead.phoneNumbers)) return ''
            return lead.phoneNumbers.join(', ')
        case 'website':         return lead.website
        case 'email':           return lead.email
        case 'contacts': {
            if(!Array.isArray(lead.contacts) || lead.contacts.length === 0) return ''

            const contacts = []

            for(const contact of lead.contacts) {
                contacts.push(`${contact.fullName} <${contact.email}>`)
            }

            return contacts.join(', ')
        }
        case 'latitude':        return lead?.location?.latitude || ''
        case 'longitude':       return lead?.location?.longitude || ''
        case 'workzone1':       return lead.zone1
        case 'workzone2':       return lead.zone2
        case 'workzone3':       return lead.zone3
        case 'workzone4':       return lead.zone4
        case 'region1':         return lead.region
        case 'tags':
            if(!Array.isArray(lead.tags)) return ''
            return lead.tags.join(', ')
        case 'type':            return lead.projecttype
        case 'customer_type':   return lead.customertype
        case 'last_comment':
            if(!Array.isArray(lead.comments) || lead.comments.length === 0) return ''

            const userComments = lead.comments.filter(c => {
                return c.commentor !== 'system' && !c.systemComment && !c.systemComment?.type
            })
      
            if(userComments.length === 0) return ''
    
            userComments.sort(appHelpers.stringSort.bind(null, 'desc', 'postedAt'))

            if(!userComments[0].comment) return ''

            const lastComment = userComments[0]

            return `${lastComment.commentor} (${dayjs(lastComment.postedAt).format('ll')}): ${lastComment.comment}`

        default: return ''
    }
}


function _getQuestionFieldValue(lead, column) {
    if(column.templateQuestion.type === 'slider' || (column.templateQuestion.type === 'value' && column.templateQuestion.quantitative_type === 'normal')) {
        // Slider and "value" question that's no a "quantitative" question,
        // answer is in answer.value
        for(const assignment of lead.assignments) {
            if(assignment.answers[column.templateQuestion._id]) {
                return assignment.answers[column.templateQuestion._id].answer.value
            }

        }
    } else if(column.templateQuestion.type === 'value' && column.templateQuestion.quantitative_type !== 'normal') {
        // "value" question that's a "quantitative" question, answer is is answer.quantity
        for(const assignment of lead.assignments) {
            if(assignment.answers[column.templateQuestion._id]) {
                return assignment.answers[column.templateQuestion._id].answer.quantity
            }
        }
    } else if(column.templateQuestion.type === 'multiple' || column.templateQuestion.type === 'multiple_many') {
        // Multiple choice question, answer is in answer.choices
        for(const assignment of lead.assignments) {
            if(assignment.answers[column.templateQuestion._id]) {
                return assignment.answers[column.templateQuestion._id].answer.choices.join(', ')
            }
        }
    }

    return ''
}